@import url('https://fonts.googleapis.com/css2?family=Noto+Sans:wght@400;700&display=swap');
@import '~normalize.css';

@import './loader.scss';

body {
  background: var(--background);
  color: var(--textColor);
  font-family: 'Noto Sans', sans-serif;
  line-height: 1.5;
  font-size: 1rem;
  padding: 0.5rem;
  transition: color 0.2s ease-out, background 0.2s ease-out;

  h2 {
    font-size: 1.5rem;
    margin: 0;
  }

  h3 {
    font-size: 1.25rem;
    font-weight: normal;
    text-transform: uppercase;
  }

  &.light {
    --background: #ffffff;
    --textColor: #262626;
    --separatorColor: #a5a5a5;
    --boxShadow: 0 0 4px #a5a5a5;
    --linkColor: #0078c9;
    --buttonBackground: #0078c9;
    --buttonText: #ffffff;
    --danger: #ff0018;
    --successText: #155724;
    --successBackground: #d4edda;
    --dangerText: #721c24;
    --dangerBackground: #f8d7da;
  }

  &.dark {
    --background: #262626;
    --textColor: #f1f1f1;
    --separatorColor: #a5a5a5;
    --boxShadow: 0 0 4px #a5a5a5;
    --linkColor: #0098ff;
    --buttonBackground: #0078c9;
    --buttonText: #ffffff;
    --danger: #ff7582;
    --successText: #d4edda;
    --successBackground: #155724;
    --dangerText: #f8d7da;
    --dangerBackground: #721c24;
  }
}

@media screen and (min-width: 1080px) {
  main {
    width: 990px;
    margin: auto;
    padding: 0;
  }
}

hr {
  border: none;
  border-top: 1px solid var(--separatorColor);
}

a {
  color: var(--linkColor);
  text-decoration: none;
  cursor: pointer;
  padding-left: 0.5rem;
}

svg {
  cursor: pointer;
}

address {
  font-style: normal;
}

section:last-child {
  padding-bottom: 10rem;
}

.article__header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1.5rem 0 0;
}

.contact-address {
  display: flex;
  flex-direction: column;
  margin-bottom: 2rem;

  @media screen and (min-width: 670px) {
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
  }

  img {
    max-width: 100%;

    @media screen and (min-width: 670px) {
      max-width: 400px;
    }
  }
}

.product {
  &__card {
    display: flex;
    padding: 1rem;
    border-radius: 4px;
    box-shadow: var(--boxShadow);
    flex-direction: column;
    margin-top: 1rem;

    @media screen and (min-width: 670px) {
      &--top {
        display: flex;
      }
    }

    img {
      max-width: 200px;
    }
  }

  &__image {
    text-align: center;
    flex: 1 1 0;
  }

  &__description {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex: 2 1 0;

    @media screen and (min-width: 670px) {
      margin-left: 2rem;
    }

    button {
      border: none;
      background: transparent;
      text-transform: uppercase;
      color: var(--linkColor);
      padding: 1rem;
      cursor: pointer;
    }
  }
}

.contact-form {
  display: flex;
  flex-direction: column;

  label {
    display: flex;
    flex-direction: column;
    margin-bottom: 1rem;

    input,
    textarea {
      padding: 0.5rem;
      border: 1px solid var(--separatorColor);
      border-radius: 4px;
      background-color: var(--background);
      color: var(--textColor);

      &::placeholder {
        opacity: 0.5;
        font-style: italic;
      }
    }

    textarea {
      resize: vertical;
    }

    &.contact-form__invalid {
      color: var(--danger);

      input,
      textarea {
        border: 1px solid var(--danger);
      }
    }
  }

  button {
    padding: 1rem;
    border: none;
    background-color: var(--buttonBackground);
    color: var(--buttonText);
    border-radius: 4px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;

    &:disabled {
      opacity: 0.75;
      cursor: not-allowed;
    }
  }

  &__error {
    color: var(--dangerText);
    background-color: var(--dangerBackground);
    padding: 0.5rem;
    border-radius: 4px;
  }

  &__success {
    color: var(--successText);
    background-color: var(--successBackground);
    padding: 0.5rem;
    border-radius: 4px;
  }
}
